import React, { useState } from "react";
import classes from "./register.module.css";
import faceLogo from "../Images/faceLogo.png";
import Navbar from "../Navbar/Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();

  const fields = [
    { label: "Name", name: "name" },
    { label: "Employee ID", name: "empid" },
    { label: "Company Email", name: "email" },
    { label: "Contact Number", name: "phoneNumber" },
  ];

  const [formData, setFormData] = useState({});

  // Function to update form data
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    console.log(formData);
    const empid = formData.empid;
    formData.accountNumber = "Test";
    formData.password = "Test1234";
    axios
      .post(`https://www.iterve.in/apollouser`, formData, {
        params: { empid },
      })
      .then(() => {
        localStorage.setItem("empid", formData.empid);
        console.log(localStorage.getItem("empid"));
        console.log("swoosh");
        navigate("/reports");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={classes.container}>
      <Navbar />
      <div className={classes.content}>
        <div className={classes.left}>
          <div className={classes.heading}>Submit Your Details</div>
          {fields.map((field, index) => (
            <div key={index} className={classes.lableNameWrap}>
              <div className={classes.label}>{field.label}:</div>
              <input
                name={field.name}
                className={classes.inputfield}
                autoComplete="off"
                onChange={handleInputChange}
              />
            </div>
          ))}
          <button className={classes.btn} onClick={handleSubmit}>
            Submit
          </button>
        </div>
        <div className={classes.right}>
          <img src={faceLogo} alt="apolloLogo" className={classes.faceLogo} />
        </div>
      </div>
    </div>
  );
};

export default Register;
