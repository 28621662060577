import React, { useState, useEffect } from "react";
import classes from "./Selftest.module.css";
import Navbar from "../Navbar/Navbar";
import {
  updateInput,
  validateForm1,
  validateForm2,
  familyOptionsToBinaryString,
  medicationOptionsToBinaryString,
} from "./formUtil.js";
import WebcamRecorder from "./circ-webcam";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Form1 from "./form1.js";
import Form2 from "./form2.js";

const Selftest = () => {
  const navigate = useNavigate();
  const [isRecording, setRecording] = useState(false);
  const [isModelLoading, setModelLoading] = useState(false);
  const [huntToken, setHuntToken] = useState(false);
  const [show2ndForm, setShow2ndForm] = useState(false);
  const [formJsonData, setFormJsonData] = useState();
  const [t, sett] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isAlertActive, setAlertActive] = useState(false);

  const location = useLocation();

  function alertWDC(message) {
    setAlertActive(true);
    alert(message);
    setTimeout(() => setAlertActive(false), 100);
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const findtokens = async (token) => {
    try {
      const response = await axios.get(`https://www.iterve.in/token`, {
        params: { token },
      });
      console.log("res" + response.data.token);
      console.log(
        `https://www.iterve.in/selftest/token=${response.data.token}`
      );
    } catch (e) {
      console.log(e);
      alertWDC("invalid link");
      navigate("/registerUser");
    }
  };

  useEffect(() => {
    if (huntToken) {
      const t = location.pathname.split("/").pop();

      const token = t.slice(6);
      sett(token);
      console.log("t", t, token);
      findtokens(token);
    }
  }, [huntToken]);

  useEffect(() => {
    window.scroll(0, 0);

    console.log(location.state);

    const t = location.pathname.split("/").pop();

    const token = t.slice(6);

    if (location.state !== "fromApolloMember") {
      console.log("token hunting is initiated");
      setHuntToken(true);
    }
  }, [location.state]);

  const goToNextForm = (e) => {
    if (isRecording) return;
    const formData = updateInput(e, alertWDC);

    if (!formData) return;
    setFormJsonData(formData);
    setShow2ndForm(true);
  };

  // Function to handle start of recording
  const startRecording = (
    e,
    selectedOptionsDiabeteic,
    selectedOptionsHyperTension,
    selectedOptionsMedication
  ) => {
    if (e) e.preventDefault();
    if (isRecording) return;
    if (!isChecked) {
      alertWDC("Please agree to the privacy policy");
      return;
    }
    const formData = validateForm2(formJsonData);
    // console.log(selectedOptionsDiabeteic, selectedOptionsHyperTension, selectedOptionsMedication);

    if (!formData) return;
    formData.append(
      "familyDiabetes",
      familyOptionsToBinaryString(selectedOptionsDiabeteic)
    );
    formData.append(
      "familyHypertension",
      familyOptionsToBinaryString(selectedOptionsHyperTension)
    );
    formData.append(
      "medication",
      medicationOptionsToBinaryString(selectedOptionsMedication)
    );
    setFormJsonData(formData);
    setModelLoading(performance.now());
    // setJsonData(details);
    setRecording(true);
  };

  return (
    <div className={classes.container} id="content">
      <Navbar />
      <div className={classes.pageHeading}>Vitals measurement</div>
      <div className={classes.contentLayout}>
        <div className={classes.alertDiv}>
          Note: Before the video recording starts, please make sure the room is
          properly lit, especially your face. Also, make sure that no backlight
          is shining directly into the camera.
        </div>
        <div className={classes.mainContent}>
          <div className={classes.detailsContainer}>
            {show2ndForm ? (
              <Form2
                classes={classes}
                location={location}
                startRecording={startRecording}
                setShow2ndForm={setShow2ndForm}
                isChecked={isChecked}
              />
            ) : (
              <Form1
                classes={classes}
                location={location}
                goToNextForm={goToNextForm}
                formJsonData={formJsonData}
                alertWDC={alertWDC}
              />
            )}
          </div>

          <div className={classes.image}>
            <WebcamRecorder
              startRecording={isRecording}
              setRecording={setRecording}
              setLoading={setModelLoading}
              token={t}
              formData={formJsonData}
              isAlertActive={isAlertActive}
              setAlertActive={setAlertActive}
            />
            {/* <img src={face} alt="Face landmarks"></img> */}
            <div className={classes.signoutSec}>
              {window.innerWidth >= 768 ? (
                <button
                  onClick={() => {
                    setRecording(false);
                    if (show2ndForm) setShow2ndForm(false);
                    else navigate("/reports");
                  }}
                  className={classes.signout}
                >
                  Back
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className={classes.tncContainer}>
          <div className={classes.disclaimer}>
            Your video and picture is not stored in our database; our system
            extracts only RGB and rPPG from camera processing and uses it to
            derive your health vitals.
          </div>
          <label className={classes.checkboxLabel}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className={classes.checkbox}
            />
            I agree to share my data for research and training purposes.
          </label>
        </div>

        {window.innerWidth < 768 ? (
          <button
            onClick={() => {
              setRecording(false);
              if (show2ndForm) setShow2ndForm(false);
              else navigate("/reports");
            }}
            className={classes.back}
          >
            Back
          </button>
        ) : (
          <></>
        )}
      </div>
      {isModelLoading && (
        <div className={classes.loadingOverlay}>
          <div className={classes.loadingText}>Please wait...</div>
        </div>
      )}
    </div>
  );
};

export default Selftest;
