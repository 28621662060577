// Function to crop the mask based on ROI
export const cropMask = (ctx, points_arr, offsetX, offsetY) => {
  // const combinedPath = new Path2D();
  ctx.beginPath();
  points_arr.forEach((points) => {
    ctx.moveTo(points[0].x - offsetX, points[0].y - offsetY);
    for (let i = 1; i < points.length; i++) {
      ctx.lineTo(points[i].x - offsetX, points[i].y - offsetY);
    }
    ctx.closePath();
  });

  ctx.rect(0, 0, ctx.canvas.width, ctx.canvas.height);
  ctx.fillStyle = "black";
  ctx.fill("evenodd");
  const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
  // console.log(imageData)
  return extractMeanRGBofImageData(imageData.data);
};

// Function to extract RGB values from pixels
function extractMeanRGBofImageData(pixels) {
  let totalRed = 0;
  let totalGreen = 0;
  let totalBlue = 0;
  let count = 0;
  for (let i = 0; i < pixels.length; i += 4) {
    totalRed += pixels[i];
    totalGreen += pixels[i + 1];
    totalBlue += pixels[i + 2];
    if (pixels[i] > 0 || pixels[i + 1] > 0 || pixels[i + 2] > 0) count++;
  }

  return [totalRed / count, totalGreen / count, totalBlue / count];
}

export function convertImageToCanvas(imageElement, box = null) {
  // Create a new canvas element
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  // Set the canvas dimensions
  canvas.width = box ? box.width : imageElement.width;
  canvas.height = box ? box.height : imageElement.height;

  // Draw the image on the canvas
  if (box) {
    context.drawImage(
      imageElement,
      box.xMin,
      box.yMin,
      box.width,
      box.height,
      0,
      0,
      box.width,
      box.height
    );
  } else {
    context.drawImage(
      imageElement,
      0,
      0,
      context.canvas.width,
      context.canvas.height
    );
  }
  // console.log(imageElement.src, createImageURLFromImageData(context.getImageData(0, 0, context.canvas.width, context.canvas.height)))
  return context;
}

// Function to create a data URL from ImageData
function createImageURLFromImageData(imageData) {
  const canvas = document.createElement("canvas");
  canvas.width = imageData.width;
  canvas.height = imageData.height;

  const ctx = canvas.getContext("2d");
  ctx.putImageData(imageData, 0, 0);

  const imageURL = canvas.toDataURL("image/png");
  return imageURL;
}
