import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./signOut.module.css";
import userdata from "../typesofusers.json";

const SignOut = () => {
  const accno = localStorage.getItem("accno");
  const navigate = useNavigate();

  function signoutfunc() {
    localStorage.removeItem("accno");
    if (userdata.form2user && userdata.form2user.includes(accno)) {
      axios
        .put("https://www.iterve.in/apollouser1", {
          params: { accno: accno, deviceId: "" },
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .put("https://www.iterve.in/apollouser", {
          params: { accno: accno, deviceId: "" },
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    navigate("/login");
    window.location.reload();
  }

  return (
    <button onClick={signoutfunc} className={classes.signout}>
      SIGN OUT
    </button>
  );
};

export default SignOut;
