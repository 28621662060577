import React, { useState } from "react";
import classes from "./Form3.module.css";
import DownButton from "../Images/DownButton.png";
import axios from "axios";

const typesData = [
  {
    type: "Type 1",
    options: [
      "Lack of strength",
      "Craving for warmth",
      "Bloating",
      "Emaciation (Skinny/sudden weight loss)",
      "Difficulty in breathing",
      "Constipation",
      "Dizziness, giddiness",
      "Lack of strength in sense organs",
      "Depression, anxiety",
      "Cutting pain",
      "Roughness",
    ],
  },
  {
    type: "Type 2",
    options: [
      "Decreased activity(Lethargy)",
      "Debility and organs",
      "Tiredness if after specking after few words",
      "Delusion",
    ],
  },
  {
    type: "Type 3",
    options: [
      "Burning sensation",
      "Increased heat in the body",
      "Syncope (Fainting)",
      "Hyperhidrosis (Excessive sweating)",
      "Polyphagia (Excessive hunger)",
      "Insomnia",
      "Thirst",
      "Acidic belching",
      "Erythema (Reddish discoloration)",
      "Inflammation",
      "Jaundice (Yellowish discoloration)",
    ],
  },
  {
    type: "Type 4",
    options: [
      "Poor digestion",
      "Cold feet and hand",
      "Lack of warmth",
      "Dullness",
    ],
  },
  {
    type: "Type 5",
    options: [
      "Aversion to Food",
      "Feeling heaviness in Body Part",
      "Lethargy and Laziness",
      "Excessive Body Fat",
      "Nausea",
      "Chest Heaviness",
      "Swelling in Body Parts",
      "Itching",
      "Indigestion",
      "Excessive Sleep",
      "Whitish Discoloration",
      "Breathlessness",
      "Excessive Salivation",
    ],
  },
  {
    type: "Type 6",
    options: [
      "Dryness in the skin",
      "Lack of sleep",
      "Tiredness / weakness",
      "Frequent infection",
    ],
  },
];

const Form3 = ({ setSymptomsSubmitted, setDoshabalances, alertWDC }) => {
  const [selectedOptionsByType, setSelectedOptionsByType] = useState({});
  const [currentTypeIndex, setCurrentTypeIndex] = useState(0);
  const [tempSelectedOptions, setTempSelectedOptions] = useState({});
  const [isOptionSelected, setIsOptionSelected] = useState({});

  const handleOptionClick = (type, option) => {
    setTempSelectedOptions((prevState) => {
      const currentOptions = prevState[type] || [];
      const isSelected = currentOptions.includes(option);

      const newOptions = isSelected
        ? currentOptions.filter((item) => item !== option)
        : [...currentOptions, option];

      return {
        ...prevState,
        [type]: newOptions,
      };
    });
  };

  const toggleOpen = (typeIndex) => {
    setCurrentTypeIndex((prevIndex) =>
      prevIndex === typeIndex ? null : typeIndex
    );
  };

  const handleSubmit = (type) => {
    setSelectedOptionsByType((prevState) => ({
      ...prevState,
      [type]: tempSelectedOptions[type] || [],
    }));

    setIsOptionSelected((prevSelectedState) => ({
      ...prevSelectedState,
      [type]: true,
    }));

    if (currentTypeIndex === typesData.length - 1) {
      setCurrentTypeIndex(null);
      return;
    }
    setCurrentTypeIndex((prevIndex) => (prevIndex + 1) % typesData.length);
  };

  const handleFinalSubmit = () => {
    const allTypesSubmitted = typesData.every(
      (typeData) => isOptionSelected[typeData.type]
    );

    console.log("Selected options by type: ", selectedOptionsByType);

    if (!allTypesSubmitted) {
      alertWDC("Please submit all types before final submission.");
      return;
    } else {
      alertWDC("Types successfully submitted.");
    }

    axios
      .post("https://www.iterve.in/apollouser1/ayurveda", selectedOptionsByType)
      .then((response) => {
        setDoshabalances(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setSymptomsSubmitted(true);
  };

  const allTypesSubmitted = typesData.every(
    (typeData) => isOptionSelected[typeData.type]
  );

  return (
    <div className={classes.container}>
      {typesData.map((typeData, index) => (
        <TypeComponent
          key={index}
          type={typeData.type}
          options={typeData.options}
          selectedOptions={tempSelectedOptions[typeData.type] || []}
          onOptionClick={handleOptionClick}
          isOpen={currentTypeIndex === index}
          toggleOpen={() => toggleOpen(index)}
          onSubmit={() => handleSubmit(typeData.type)}
          isAnyOptionSelected={isOptionSelected[typeData.type] || false}
        />
      ))}
      <button
        onClick={handleFinalSubmit}
        className={classes.finalSubmitBtn}
        style={{
          backgroundColor: allTypesSubmitted ? "#04122c" : "#D3D3D3",
        }}
      >
        Submit
      </button>
    </div>
  );
};

const TypeComponent = ({
  type,
  options,
  selectedOptions,
  onOptionClick,
  isOpen,
  toggleOpen,
  onSubmit,
  isAnyOptionSelected,
}) => {
  return (
    <div>
      <div
        onClick={toggleOpen}
        className={classes.typeContainer}
        style={{
          backgroundColor: isAnyOptionSelected ? "#D9D9D9" : "#ffffff",
        }}
      >
        <div style={{ width: "1.5vw" }}></div>
        <div className={classes.typeName}>{type}</div>
        <img
          src={DownButton}
          alt="down button"
          className={classes.downButton}
        />
      </div>

      {isOpen && (
        <div className={classes.dropItems}>
          <div className={classes.optionContainer}>
            {options.map((option, index) => (
              <div
                className={classes.option}
                key={index}
                onClick={() => onOptionClick(type, option)}
                style={{
                  backgroundColor: selectedOptions.includes(option)
                    ? "#D9D9D9"
                    : "#ffffff",
                }}
              >
                {option}
              </div>
            ))}
          </div>
          <button onClick={() => onSubmit(type)} className={classes.submitBtn}>
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default Form3;
