import React, { useState } from "react";
import classes from "./Form2.module.css";
import axios from "axios";

const Form2 = ({ setSymptomClicked, setDoshavalues, alertWDC }) => {
  const [allanswered, setallanswered] = useState(false);

  const questions = [
    {
      id: 1,
      questionText: "Which best describes your body type?",
      options: [
        "Slender and Lean",
        "Medium",
        "Athletic and muscular",
        "None of the above",
      ],
    },
    {
      id: 2,
      questionText: "How would you describe your hair type?",
      options: [
        "Dry with split ends",
        "Normal, fine, prone to hair loss",
        "Oily and thick",
        "None of the above",
      ],
    },
    {
      id: 3,
      questionText: "Skin Type?",
      options: [
        "Dry",
        "Smooth, more sweating, acne",
        "Hydrated",
        "None of the above",
      ],
    },
    {
      id: 4,
      questionText: "Body Weight?",
      options: [
        "Low, difficult to gain weight",
        "Medium, can easily put on or lose weight",
        "Overweight, difficult to lose weight",
        "None of the above",
      ],
    },
    {
      id: 5,
      questionText: "Size and colour of teeth",
      options: [
        "Big, blackish",
        "Medium, yellow",
        "Large, white",
        "None of the above",
      ],
    },
    {
      id: 6,
      questionText: "Pace of Performing Work",
      options: [
        "Fast, always in hurry",
        "Medium, energetic",
        "Slow, steady",
        "None of the above",
      ],
    },
    {
      id: 7,
      questionText: "Memory",
      options: ["Short term", "Good", "Long term", "None of the above"],
    },
    {
      id: 8,
      questionText: "Sleep Pattern",
      options: ["Less", "Moderate", "Sleepy", "None of the above"],
    },
    {
      id: 9,
      questionText: "Climate",
      options: [
        "Dislike winter",
        "Dislike summer",
        "Dislike monsoon",
        "None of the above",
      ],
    },
    {
      id: 10,
      questionText: "Appetite",
      options: [
        "Any time",
        "Can skip meal",
        "Sharp hunger",
        "None of the above",
      ],
    },
    {
      id: 11,
      questionText: "Body temperature",
      options: [
        "Normal",
        "More than normal",
        "Slightly cold",
        "None of the above",
      ],
    },
    {
      id: 12,
      questionText: "Stamina",
      options: [
        "Low energy, gets easily tired",
        "Moderate energy",
        "Excellent energy",
        "None of the above",
      ],
    },
    {
      id: 13,
      questionText: "Bowel Movements",
      options: [
        "Regular and well-formed stools, good digestion",
        "Variable or occasional issues like loose stools or acidity",
        "Irregular or difficult bowel movements, inconsistent digestion",
        "None of the above",
      ],
    },
  ];

  // State to hold selected answers (as option numbers)
  const [answers, setAnswers] = useState(Array(questions.length).fill(null));
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);

  // Handle option change
  const handleOptionChange = (questionId, selectedOptionIndex) => {
    const newAnswers = [...answers];
    newAnswers[questionId - 1] = selectedOptionIndex + 1; // Store the option number (1-based index)
    setAnswers(newAnswers);
    setSelectedQuestionId(questionId);

    // Check if all questions are answered
    const allAnswered = newAnswers.every((answer) => answer !== null);
    setallanswered(allAnswered);
  };
  // Handle the "Next" button click
  const handleNextClick = async () => {
    const result = questions.map((question, index) => ({
      question: question.questionText,
      answer: answers[index],
    }));

    const arr = result.map((item) => item.answer);

    if (!allanswered) {
      alertWDC("Please answer all questions");
      return;
    }
    console.log(arr);
    setSymptomClicked(true);
    await axios
      .post("https://www.iterve.in/apollouser1/doshas", {
        params: { choices: arr },
      })
      .then((res) => {
        setDoshavalues(res.data);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        alertWDC("Failed to post");
      });
    console.log(result);
    // Navigate or perform other actions here
  };

  return (
    <div className={classes.container} id="content">
      {questions.map((question) => (
        <div
          key={question.id}
          className={`${classes.questionContainer} ${
            selectedQuestionId === question.id ? classes.elevated : ""
          }`}
        >
          <p className={classes.questionText}>
            {question.id}. {question.questionText}
          </p>
          {question.options.map((option, index) => (
            <div key={index} className={classes.optionContainer}>
              <input
                type="radio"
                name={`question-${question.id}`}
                value={index + 1}
                checked={answers[question.id - 1] === index + 1}
                onChange={() => handleOptionChange(question.id, index)}
                style={{ cursor: "pointer" }}
              />
              <label
                // htmlFor={`question-${question.id}-option-${index}`}
                className={classes.optionLabel}
                onClick={() => handleOptionChange(question.id, index)}
              >
                {option}
              </label>
            </div>
          ))}
        </div>
      ))}
      <button
        onClick={handleNextClick}
        className={allanswered ? classes.nextButton : classes.nextButton1}
        style={{ cursor: "pointer" }}
      >
        Select your symptoms
      </button>
    </div>
  );
};

export default Form2;
