import React, { useState, useRef, useEffect } from "react";
import classes from "./reportVitals.module.css";
import apolloLogo from "../Images/apolloLogo.png";
import aivotLogo from "../Images/aivotLogo 2.png";
import watermark from "../Images/watermark.png";
import HiimsLogo from "../Images/HIIMSLogo.png";
import { useLocation } from "react-router-dom";
import { PDFDownloadLink, View, pdf } from "@react-pdf/renderer";
import MyPDF from "./myPDF";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import wpimage from "../Images/whatsapp.png";
import mailimage from "../Images/mail.png";
import appleimage from "../Images/apple.png";
import androidimage from "../Images/android.png";
import webimage from "../Images/www.png";
import copyimage from "../Images/copy.png";
import copydone from "../Images/done.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import tickimage from "../Images/tick.png";
import cross from "../Images/remove.png";
import gamil from "../Images/gmail.png";
import yahoo from "../Images/yahoo.png";
import outlook from "../Images/outlook.png";
import userdata from "../typesofusers.json";

const ReportVitals = () => {
  const mypdfRef = useRef(null);
  const [isDownloaded, setDownloaded] = useState(null);
  const [blobUrl, setBlobUrl] = useState("");
  const [healthParameters, sethealthParameters] = useState([]);
  const [formData, setFormData] = useState({});
  const [vitals, setvitals] = useState();
  const [childid, setchildid] = useState("");
  const [parentid, setparentid] = useState("");
  const [sharepopup, setsharepopup] = useState(false);
  const [whatsappopt, setwhatsappopt] = useState(false);
  const [copycompleted, setcopycompleted] = useState(false);
  const [accountNumber, setaccountNumber] = useState("");
  const [enduserid, setenduserid] = useState("");
  const [mailopt, setmailopt] = useState(false);
  console.log("vitals page rendered");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(parentid, childid);

    if (location.state) {
      setvitals(location.state);
      console.log(location.state);
      setenduserid(location.state.id);
      console.log(localStorage.getItem("accno"));
      var accno = localStorage.getItem("accno");
      if (!accno) {
        accno = "";
      }
      setaccountNumber(accno);
      if (accno !== "") {
        console.log("accno exists", accno);
        var findid = "";
        console.log("before report vitals", accno);
        const isFromTestUser =
          userdata.form2user &&
          userdata.form2user.includes(localStorage.getItem("accno"));
        axios
          .get(
            `https://www.iterve.in/${
              isFromTestUser ? "apollouser1" : "apollouser"
            }/getuserid`,
            { params: { accno } }
          )
          .then((res) => {
            findid = res.data;
            console.log(res.data);
            setparentid(res.data);
          })
          .catch((e) => {
            console.log(e);
          });
        setchildid(location.state._id);
      } else {
        setparentid("enduser");
        setchildid(location.state.id);
      }
      // fetch user Id as object id and set it
    } else {
      console.log("else");
      const objectId = location.pathname.split("/")[2].slice(4);
      const vitalsDataId = location.pathname.split("/")[3].slice(4);
      setparentid(objectId);
      setchildid(vitalsDataId);
      if (!objectId || !vitalsDataId) navigate("/reports");
      const dbcollection = objectId === "enduser" ? "enduser" : "apollouser";

      fetch(
        `https://www.iterve.in/${dbcollection}/fetchSingleVital?objectId=${objectId}&vitalDataId=${vitalsDataId}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          if (res.message) throw new Error("Network response was not ok");
          setvitals(objectId === "enduser" ? res : res.vitalsData);
          setaccountNumber(res.accountNumber ? res.accountNumber : "");
        })
        .catch(() => {
          console.log("catch part", objectId, vitalsDataId);
          fetch(
            `https://www.iterve.in/apollouser1/fetchSingleVital?objectId=${objectId}&vitalDataId=${vitalsDataId}`,
            {
              method: "GET",
            }
          )
            .then((res) => res.json())
            .then((res) => {
              console.log(res);
              setvitals(res.vitalsData);
              setaccountNumber(res.accountNumber ? res.accountNumber : "");
            });
        });
    }
  }, [location.state]);

  useEffect(() => {
    console.log(vitals);

    if (vitals) {
      sethealthParameters([
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "Beats per min",
          referenceRange: "55 - 100",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        {
          vital: "Breathing Rate",
          value: vitals["breathing_rate"],
          unit: "BPM",
          referenceRange: "12 – 20",
        },
        {
          vital: "Pulse Rate Quotient",
          value: vitals["pulse_rate_quotient"],
          unit: "Beats per min",
          referenceRange: "4",
        },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "Kg/m^2",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: "50% - 60%",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: "M < 24, F < 31",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "1 - 12",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: "M 8-25, F 20-35",
        },
        {
          vital: "RMSSD",
          value: vitals["rmssd"],
          unit: "milli sec",
          referenceRange: "19 – 107",
        },
        {
          vital: "SDNN",
          value: vitals["sdnn"],
          unit: "milli sec",
          referenceRange: "50 – 100",
        },
        {
          vital: "SD1",
          value: vitals["sd1"],
          unit: "milli sec",
          referenceRange: "3 - 20",
        },
        {
          vital: "SD2",
          value: vitals["sd2"],
          unit: "milli sec",
          referenceRange: ">20",
        },
        {
          vital: "IBI",
          value: vitals["ibi"],
          unit: "milli sec",
          referenceRange: "600 – 900",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "0 – 100",
        },
        {
          vital:
            userdata.form2user &&
            userdata.form2user.includes(localStorage.getItem("accno"))
              ? "SBP"
              : "SBP (As entered)",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "90 – 120",
        },
        {
          vital:
            userdata.form2user &&
            userdata.form2user.includes(localStorage.getItem("accno"))
              ? "DBP"
              : "DBP (As entered)",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "< 90",
        },

        {
          vital: "Mean Arterial Pressure",
          value: vitals["mean_atrial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "40 – 60",
        },
        {
          vital: "Cardiac Output",
          value: vitals["cardiac_output"],
          unit: "L/min",
          referenceRange: "5 – 6",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L(min.m^2)",
          referenceRange: "2.5 – 4",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "milli litre",
          referenceRange: "50 - 100",
        },
        // {
        //   vital: "LDL",
        //   value: vitals["ldl"],
        //   unit: "mg/dl",
        //   referenceRange: "< 100",
        // },
        // {
        //   vital: "HDL",
        //   value: vitals["hdl"],
        //   unit: "mg/dl",
        //   referenceRange: "40-60",
        // },
        // {
        //   vital: "Triglycerides",
        //   value: vitals["triglycerides"],
        //   unit: "mg/dl",
        //   referenceRange: "< 150",
        // },
        // {
        //   vital: "Total Cholesterol",
        //   value: vitals["total_cholestrol"],
        //   unit: "mg/dl",
        //   referenceRange: "< 200",
        // },
        // {
        //   vital: "VLDL",
        //   value: vitals["vldl"],
        //   unit: "mg/dl",
        //   referenceRange: "5 - 40",
        // },
        // {
        //   vital: "RBC Count",
        //   value: vitals["rbc_count"],
        //   unit: "million cell/mcl",
        //   referenceRange: "4.5 – 5.5",
        // },
        // {
        //   vital: "Mean Corpuscular Value",
        //   value: vitals["mean_corpuscular_volume"],
        //   unit: "fl",
        //   referenceRange: "83.0 – 101.0",
        // },
        // {
        //   vital: "Mean Corpuscular Haemoglobin",
        //   value: vitals["mean_corpuscular_hemoglobin"],
        //   unit: "pg/cells",
        //   referenceRange: "27.0 - 32.0",
        // },
        // {
        //   vital: "T3 (Triiodothyronine)",
        //   value: "",
        //   unit: "ng/dl",
        //   referenceRange: "80-200",
        // },
        // {
        //   vital: "T4 (Thyroxine)",
        //   value: "",
        //   unit: "μg/dL",
        //   referenceRange: "4.8 – 12.7",
        // },
        // {
        //   vital: "TSH (Thyroid Stimulating Hormone)",
        //   value: "",
        //   unit: "μIU/mL",
        //   referenceRange: "0.54 – 5.30",
        // },
        // {
        //   vital: "Blood Volume",
        //   value: "",
        //   unit: "mL",
        //   referenceRange: "5000",
        // },
        // {
        //   vital: "Hemoglobin",
        //   value: vitals["hemoglobin"],
        //   unit: "g/dl",
        //   referenceRange: "13.0 – 17.0",
        // },
        // {
        //   vital: "Hematocrit",
        //   value: vitals["hematocrit"],
        //   unit: "%",
        //   referenceRange: "40.0 - 50.0",
        // },
        {
          vital:
            userdata.form2user &&
            userdata.form2user.includes(localStorage.getItem("accno"))
              ? "Random Blood Sugar"
              : "Random Blood Sugar (As entered)",
          value: vitals["random_blood_sugar"],
          unit: "mg/dl",
          referenceRange: "70 – 140",
        },
        // {
        //   vital: "HbA1c",
        //   value: vitals["hba1c"],
        //   unit: "%",
        //   referenceRange: "< 5.7%",
        // },
        // {
        //   vital: "Mean Plasma Glucose",
        //   value: vitals["mean_plasma_glucose"],
        //   unit: "mg/dl",
        //   referenceRange: "70 - 100",
        // },
        // {
        //   vital: "Allowable Blood Loss",
        //   value: vitals["allowable_blood_loss"],
        //   unit: "milli litres",
        //   referenceRange: "< 14",
        // },
      ]);

      setFormData({
        name: vitals["name"],
        phoneNumber: vitals["phoneNumber"],
        email: vitals["email"],
        subjectid: vitals["subjectid"],
        gender: vitals["gender"],
        age: vitals["age"],
        height: vitals["height"],
        weight: vitals["weight"],
      });
    }
  }, [vitals]);

  const tableHead = ["Name", "Subject ID", "Gender", "Age", "Height", "Weight"];

  useEffect(() => {
    if (vitals) console.log("vitals", Object.keys(vitals), vitals);
  }, [vitals]);

  const [recipientEmail, setRecipientEmail] = useState("aniroycr537@gmail.com");
  const [file, setFile] = useState(null);

  const handleMailButtonClick = async () => {
    try {
      // Generate PDF blob
      const blob = await pdf(
        <MyPDF
          healthParameters={healthParameters}
          formData={formData}
          accno={accountNumber}
        />
      ).toBlob();

      // Create FormData object to send the blob
      const emailform = new FormData();
      emailform.append("file", blob, "mypdf.pdf"); // 'file' should match the field name in multer setup

      // Additional data (recipient email)
      emailform.append("recipientEmail", "aniruddha@aivot.ai");

      // Send PDF blob to backend endpoint
      const response = await fetch("https://www.iterve.in/emailsent", {
        method: "POST",
        body: emailform,
      });

      if (!response.ok) {
        throw new Error("Failed to send PDF blob.");
      }

      const responseData = await response.json();
      console.log(responseData.message); // Log success message
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  // const handleMailButtonClick = async () => {
  //   // Fetching the PDF file
  //   const blob = await pdf(<MyPDF healthParameters={healthParameters} formData={formData} />).toBlob();
  //   console.log('blob', blob);
  //   // Converting the PDF file to base64
  //   const reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   reader.onloadend = () => {
  //       const base64data = reader.result.split(',')[1];

  //       // Generate mailto link
  //       const recipient = 'recipient@gmail.com';
  //       const subject = 'Your Subject';
  //       const body = 'Please find the attached PDF.';
  //       const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}&attachment=${encodeURIComponent(base64data)}`;

  //       // Open mail app
  //       console.log(mailtoUrl);
  //       window.open(mailtoUrl, '_blank');
  //   };
  // };

  const copyToClipboard = async (text) => {
    console.log(text);
    await navigator.clipboard
      .writeText(text)
      .then(() => {
        setcopycompleted(true);
        setTimeout(() => {
          setcopycompleted(false);
        }, 3000);
        toast.success("Text copied to clipboard!", {
          autoClose: 1500,
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleWhatsappClick = async (s) => {
    //whatsapp share

    const sharelink = `https://${s}.whatsapp.com/send?text=https://www.iterve.in/shortvitals/pid=${parentid}/cid=${childid}`;
    // const a = document.createElement('a');
    // a.href=sharelink;
    // a.click();
    window.open(sharelink, "_blank");
  };

  return (
    <div className={classes.container}>
      {/* <img src={watermark} className={classes.watermark} /> */}
      <div className={classes.headingRow}>
        <div style={{ width: "15vw" }}>
          <img
            src={HiimsLogo}
            alt="Hiims Logo here"
            className={
              accountNumber
                ? accountNumber.startsWith("Hiims")
                  ? classes.logo1
                  : classes.logo1VisOff
                : classes.logo1VisOff
            }
          />
        </div>
        <div className={classes.headingText}>Your Vitals</div>
        <div style={{ width: "15vw" }}>
          <img src={aivotLogo} alt="Aivot Logo here" className={classes.logo} />
        </div>
      </div>
      {/* <div className={classes.infoSec}>
        <div
          className={classes.name}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div style={{ padding: "0 2vw" }}>Name:</div>

          <div>{formData.name}</div>
        </div>

        <div
          className={classes.email}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
          }}
        >
          <div style={{ padding: "0 2vw" }}>Email:</div>
          <div>{formData.email}</div>
        </div>
      </div>

      <div className={classes.infoSec}>
        <div
          className={classes.phoneNumber}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>PhoneNumber</div>
        </div>

        <div
          className={classes.subject}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Subject ID</div>
        </div>

        <div
          className={classes.gender}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Gender</div>
        </div>

        <div
          className={classes.age}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Age</div>
        </div>

        <div
          className={classes.height}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Height</div>
        </div>

        <div
          className={classes.weight}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
          }}
        >
          <div>Weight</div>
        </div>
      </div> */}

      <div className={classes.infoSec}>
        <div
          className={classes.name}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Name</div>
        </div>

        <div
          className={classes.subject}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Subject ID</div>
        </div>

        <div
          className={classes.gender}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Gender</div>
        </div>

        <div
          className={classes.age}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Age</div>
        </div>

        <div
          className={classes.height}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Height</div>
        </div>

        <div
          className={classes.weight}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
          }}
        >
          <div>Weight</div>
        </div>
      </div>

      <div className={classes.infoSec}>
        <div
          className={classes.name}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.name} </div>
        </div>

        <div
          className={classes.subject}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.subjectid} </div>
        </div>

        <div
          className={classes.gender}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.gender}</div>
        </div>

        <div
          className={classes.age}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.age}</div>
        </div>

        <div
          className={classes.height}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.height}</div>
        </div>

        <div
          className={classes.weight}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.weight}</div>
        </div>
      </div>

      {/* <div className={classes.infoSec}>
        <div
          className={classes.phoneNumber}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.phoneNumber} </div>
        </div>

        <div
          className={classes.subject}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.subjectId} </div>
        </div>

        <div
          className={classes.gender}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.gender}</div>
        </div>

        <div
          className={classes.age}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.age}</div>
        </div>

        <div
          className={classes.height}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.height}</div>
        </div>

        <div
          className={classes.weight}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.weight}</div>
        </div>
      </div> */}

      <div className={classes.vitalRows}>
        <div className={classes.vitalsCol}>
          <div className={classes.vital}>
            <div
              style={{ fontSize: "2vw", color: "black", fontWeight: "bold" }}
            >
              Vitals
            </div>
          </div>
          <div className={classes.value}>
            <div
              style={{ fontSize: "2vw", color: "black", fontWeight: "bold" }}
            >
              Values
            </div>
          </div>
          <div className={classes.units}>
            <div
              style={{ fontSize: "2vw", color: "black", fontWeight: "bold" }}
            >
              Units
            </div>
          </div>
          <div className={classes.range}>
            <div
              style={{ fontSize: "2vw", color: "black", fontWeight: "bold" }}
            >
              Reference Range
            </div>
          </div>
        </div>

        {healthParameters.map((param, index) => (
          <div className={classes.vitalsCol} key={index}>
            <div className={classes.vital}>
              <div>{param.vital}</div>
            </div>
            <div className={classes.value}>
              <div>{param.value}</div>
            </div>
            <div className={classes.units}>
              <div>{param.unit}</div>
            </div>
            <div className={classes.range}>
              <div>{param.referenceRange}</div>
            </div>
          </div>
        ))}
      </div>
      <div className={classes.noteSec}>
        <div>
          <b>Note:</b> These values are only indicative, cannot be used for
          clinical. The vitals marked as <b>* (asterisk)</b> are under
          evaluation for testing the models.
        </div>
      </div>

      <div className={classes.allbuttons}>
        <button
          className={classes.downloadBtn}
          onClick={() => {
            localStorage.getItem("accno") === "Test" ||
            localStorage.getItem("accno") === "csmishra" ||
            localStorage.getItem("accno") === "mehul"
              ? navigate("/reports1")
              : navigate("/reports");
          }}
        >
          Home
        </button>

        <PDFDownloadLink
          document={
            <MyPDF
              healthParameters={healthParameters}
              formData={formData}
              accno={accountNumber}
            />
          }
          fileName={`${vitals ? vitals["name"] : ""}_report.pdf`}
          className={classes.downloadBtn}
        >
          {({ loading }) => (loading ? "Loading..." : "Download now")}
        </PDFDownloadLink>

        <div className={classes.popparent}>
          {sharepopup ? (
            <>
              <div className={classes.sharepopup}>
                <img
                  src={cross}
                  className={classes.crossbtn}
                  onClick={() => setsharepopup(false)}
                />
                <div className={classes.largecont}>
                  {!whatsappopt ? (
                    <button
                      className={
                        !mailopt ? classes.sharebtn : classes.sharebtn2
                      }
                      onClick={() => {
                        setmailopt(!mailopt);
                        return false;
                      }}
                    >
                      <img src={mailimage} className={classes.wpimage}></img>
                    </button>
                  ) : (
                    <></>
                  )}
                  {mailopt ? (
                    <>
                      <div className={classes.expandArrow}></div>
                      <div className={classes.wpoptdiv}>
                        <button
                          className={classes.wpoptbtn}
                          onClick={() => handleMailButtonClick("gmail")}
                        >
                          <img src={gamil} className={classes.optimage}></img>
                        </button>
                        <button
                          className={classes.wpoptbtn}
                          onClick={() => handleMailButtonClick("yahoo")}
                        >
                          <img src={yahoo} className={classes.optimage}></img>
                        </button>
                        <button
                          className={classes.wpoptbtn}
                          onClick={() => handleMailButtonClick("outlook")}
                        >
                          <img src={outlook} className={classes.optimage}></img>
                        </button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {!mailopt && (
                    <div className={classes.wpdiv}>
                      {!whatsappopt ? (
                        <button
                          className={classes.sharebtn}
                          onClick={() => {
                            setwhatsappopt(!whatsappopt);
                          }}
                          style={{ backgroundImage: { wpimage } }}
                        >
                          <img className={classes.wpimage} src={wpimage}></img>
                        </button>
                      ) : (
                        <button
                          className={classes.sharebtn2}
                          onClick={() => {
                            setwhatsappopt(!whatsappopt);
                          }}
                          style={{ backgroundImage: { wpimage } }}
                        >
                          <img className={classes.wpimage} src={wpimage}></img>
                        </button>
                      )}
                      {whatsappopt ? (
                        <>
                          <div className={classes.expandArrow}></div>
                          <div className={classes.wpoptdiv}>
                            <button
                              className={classes.wpoptbtn}
                              onClick={() => handleWhatsappClick("web")}
                            >
                              <img
                                src={webimage}
                                className={classes.optimage}
                              ></img>
                            </button>
                            <button
                              className={classes.wpoptbtn}
                              onClick={() => handleWhatsappClick("api")}
                            >
                              <img
                                src={androidimage}
                                className={classes.optimage}
                              ></img>
                            </button>
                            <button
                              className={classes.wpoptbtn}
                              onClick={() => handleWhatsappClick("api")}
                            >
                              <img
                                src={appleimage}
                                className={classes.optimage}
                              ></img>
                            </button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
                <div className={classes.smallcont}>
                  <div
                    className={classes.copybtn}
                    onClick={() =>
                      copyToClipboard(
                        `https://www.iterve.in/shortvitals/pid=${parentid}/cid=${childid}`
                      )
                    }
                  >
                    <img
                      src={copycompleted ? tickimage : copyimage}
                      className={classes.copyimage}
                    ></img>
                    {`Copy`}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <button
            className={classes.downloadBtn}
            onClick={() => {
              setsharepopup(!sharepopup);
            }}
          >
            Share
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportVitals;
